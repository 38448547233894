document.addEventListener("alpine:init", () => {
  Alpine.data("shuffle_items", () => ({
    init() {
      this.shuffleItems()
    },
    shuffleItems() {
      const list = this.$el
      const items = Array.from(list.children)

      for (let i = items.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[items[i], items[j]] = [items[j], items[i]]
      }

      while (list.firstChild) {
        list.removeChild(list.firstChild)
      }
      items.forEach((item) => {
        list.appendChild(item)
      })
    },
  }))
})
