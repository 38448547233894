const arrowScrollbar = (options = {}) => ({
  options,
  DEFAULT_OPTIONS: { scrollStep: 5, arrow_behaviour: "hidden" },

  init() {
    this.options = { ...this.DEFAULT_OPTIONS, ...this.options }

    const scrollStep = (index) => {
      const scrollAmount = this.options.scrollStep * Math.pow(-1, index)
      this.$refs.scroll_target.scroll({
        left: this.$refs.scroll_target.scrollLeft + scrollAmount,
        behavior: "smooth",
      })
    }

    ;[this.$refs.right_arrow, this.$refs.left_arrow].forEach((arrow, index) => {
      arrow.addEventListener("click", () => scrollStep(index))
    })

    this.$refs.scroll_target.addEventListener("scroll", () => this.toggleArrowVisibility())
    this.toggleArrowVisibility()
  },

  toggleArrowVisibility() {
    const left_condition = this.$refs.scroll_target.scrollLeft == 0
    const right_condition =
      this.$refs.scroll_target.scrollWidth - this.$refs.scroll_target.scrollLeft <=
      this.$refs.scroll_target.offsetWidth + 1
    this.$refs.left_arrow.classList[left_condition ? "add" : "remove"](this.options.arrow_behaviour)
    this.$refs.right_arrow.classList[right_condition ? "add" : "remove"](this.options.arrow_behaviour)
  },
})

document.addEventListener("alpine:init", () => {
  Alpine.data("arrow_scrollbar", arrowScrollbar)
})
