document.addEventListener("alpine:init", () => {
  Alpine.data("anchored_search", (anchor) => ({
    init() {
      this.initialQuery = new URLSearchParams(window.location.search).get(this.paramName)
      this.query = this.initialQuery
    },
    paramName: "search",
    url: `${location.origin}${location.pathname}`,
    form: {
      ["@submit.prevent"]() {
        window.location.href = `${this.query ? `${this.url}?${this.paramName}=${this.query}` : this.url}#${anchor}`
      },
    },
    deleteButton: {
      ["@click.prevent"]() {
        this.query = ""
        window.location.href = `${this.url}#${anchor}`
      },
      ["x-show"]() {
        return !!this.query
      },
    },
    searchButton: {
      ["x-show"]() {
        return this.query !== this.initialQuery || !this.query
      },
      [":class"]() {
        return { "pr-6": !this.query }
      },
    },
  }))
})
