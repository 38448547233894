import Colcade from "colcade"

document.addEventListener("turbo:load", function () {
  window.grids = []

  document.querySelectorAll("[data-masonry]").forEach((el) => {
    const grid = new Colcade(el, {
      columns: ".column",
      items: ".item",
    })

    el.grid = grid
    el.append = function (htmlstr) {
      let t = document.createElement("template")
      t.innerHTML = htmlstr
      let nodes = t.content.cloneNode(true)

      el.grid.append(nodes)
    }
    window.latestMasonryElement = el
    grids.push(grid)
  })
})
