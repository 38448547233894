import componentInitializer from "@/frontend/utils/componentInitializer"
import Vue, { defineAsyncComponent } from "vue"
import store from "@/store"
import { apolloProvider } from "@/lib/Apollo"
import { i18n } from "@/lib/vue/plugins/i18n"

function createVueComponent(el, asyncComponent) {
  if (el) {
    new Vue({
      el,
      store,
      apolloProvider,
      i18n,
      render: (h) =>
        h(asyncComponent, {
          props: el.dataset,
        }),
    })
  }
}

componentInitializer(() => {
  createVueComponent(
    document.getElementById("cart-counter-btn"),
    defineAsyncComponent(() => import("@/components/Cart/CounterBtn.vue")),
  )
})
