document.addEventListener("alpine:init", () => {
  Alpine.data("share_dropdown_with_copy", (url) => ({
    url,
    open: false,
    copied: false,

    show() {
      this.open = true
    },

    hide() {
      this.open = false
    },

    toggle() {
      this.open = !this.open
    },

    copy() {
      const textarea = document.createElement("textarea")
      textarea.value = this.url
      document.body.appendChild(textarea)
      textarea.select()
      document.execCommand("copy")
      document.body.removeChild(textarea)
      this.copied = true
      setTimeout(() => (this.copied = false), 2000)
    },
  }))
})
