const MAX_SEARCH_NUMBER = 3

function createListItem(query) {
  const encodedQuery = encodeURIComponent(query)
  const li = document.createElement("li")
  li.className = "px-5 py-3 hover:bg-grey-lightest text-base flex items-center rounded-md"

  const icon = document.createElement("i")
  icon.className = "fas fa-search text-primary mr-4"

  const link = document.createElement("a")
  link.setAttribute("href", `/artworks/search/${encodedQuery}?term=${encodedQuery}`)
  link.setAttribute("data-turbo", false) // Need to disable Turbo for links in turbo-streamed response
  link.classList.add("w-full")

  const text = document.createElement("span")
  text.textContent = query

  // Assemble the elements
  link.appendChild(icon)
  link.appendChild(text)
  li.appendChild(link)

  return li
}

document.addEventListener("turbo:before-stream-render", (event) => {
  if (event.detail.newStream.target === "search_inspiration") {
    const content = event.target.querySelector("template").content
    const list = content.getElementById("recent-searches-list")
    const searches = JSON.parse(localStorage.getItem("recentSearches")) || []
    const section = content.getElementById("recent-searches")

    if (searches.length > 0) {
      for (let i = 0; i < searches.length; i++) {
        // Stop adding new items if the list already has the maximum number of items allowed
        if (list.childNodes.length >= MAX_SEARCH_NUMBER) break

        const recentSearchItem = createListItem(searches[i])
        list.appendChild(recentSearchItem)
      }

      // show the "Recent searches" section when not empty
      section.classList.remove("hidden")
    }
  }
})
