document.addEventListener("alpine:init", () => {
  Alpine.data("carousel", () => ({
    x: 0,
    isDragging: false,
    init() {
      const images = this.$refs.slider.querySelectorAll("img")
      for (let i = 0; i < images.length; i++) {
        //  blocking accidental drag behaviour
        images[i].ondragstart = () => false
      }

      this.$refs.slider.addEventListener("mousemove", (e) => this.onMove(e))
      this.$refs.slider.addEventListener("mouseup", () => this.onUp())
    },
    cleanup() {
      this.$refs.slider.removeEventListener("mousemove", this.onMove)
      this.$refs.slider.removeEventListener("mouseup", this.onUp)
    },
    scroll(amount) {
      const slide = this.$refs.slider_img
      const currentOffset = this.$refs.slider.scrollLeft
      const currentIndex = Math.floor(currentOffset / slide.clientWidth)
      const newIndex = currentIndex + amount
      const newOffset = newIndex * slide.clientWidth
      const delta = newOffset - currentOffset

      this.$refs.slider.scrollBy({ left: delta, behavior: "smooth" })
    },
    scrollLeft() {
      this.scroll(-1)
    },
    scrollRight() {
      this.scroll(1)
    },
    onMove(event) {
      if (this.isDragging) {
        const delta = event.clientX - this.x
        this.x = event.clientX

        this.$refs.slider.scrollLeft -= delta
      }
    },
    onUp() {
      // when mouse's is up, prevent "mousemove" event from ongoing actions
      this.isDragging = false
    },
    slider: {
      ["@mousedown"]() {
        this.x = this.$event.clientX

        this.isDragging = true
      },
    },
  }))
})
