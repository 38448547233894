import componentInitializer from "@/frontend/utils/componentInitializer"
import Vue, { defineAsyncComponent } from "vue"
import store from "@/store"
import { apolloProvider } from "@/lib/Apollo"

componentInitializer(() => {
  const target = document.getElementById("nav-favourite")

  if (target) {
    const Favourite = defineAsyncComponent(() => import("@/components/Nav/Favourite.vue"))

    new Vue({
      el: target,
      store,
      apolloProvider,
      render: (h) =>
        h(Favourite, {
          props: target.dataset,
        }),
    })
  }
})
