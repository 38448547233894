import Blytics, { ImpressionListingsDOM } from "@/lib/Blytics"

$(document).on("turbo:load", ImpressionListingsDOM)
$(document).on("turbo:load", function () {
  window.Blytics = Blytics
})

// TODO: Investigate: Neither `data-blytics-listing-share` nor `data-blytics-artist-share` attributes
// don't visibly exist as HTML data elements anywhere in the code. Thus we haven't been sending
// share events to analytics for nobody knows how long ¯\_(ツ)_/¯

$("[data-blytics-listing-share]").on("click", function () {
  const self = $(this)

  Blytics.push("share", [
    {
      target: self.data("blyticsListingShare"),
      entity: "Listing",
      entity_id: self.data("id"),
      parent: "Artist",
      parent_id: self.data("artistId"),
      user_id: window.config.userID,
      user_agent: window.navigator.userAgent,
    },
  ])
})

$("[data-blytics-artist-share]").on("click", function () {
  const self = $(this)

  Blytics.push("share", [
    {
      target: self.data("blyticsListingShare"),
      entity: "Artist",
      entity_id: self.data("id"),
      user_id: window.config.userID,
      user_agent: window.navigator.userAgent,
    },
  ])
})
