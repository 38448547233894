import eventBus from "@/lib/vue/plugins/event_bus"

document.addEventListener("alpine:init", () => {
  Alpine.data("turbo_tooltip_drawer", () => ({
    open: false,
    cached_data: {},
    last_trigger: undefined,

    init() {
      $(document).on("turbo:load", () => this.registerListenersOn(document))
      eventBus.$on("turbo-tooltip:register", (e) => this.registerListenersOn(e.trigger_container))

      document.addEventListener("turbo:before-stream-render", (e) => {
        if (this.last_trigger && e.detail.newStream.target === "turbo-tooltip")
          this.cached_data[this.last_trigger] = e.detail.newStream.outerHTML
      })
    },

    registerListenersOn(container) {
      Array.from(container.querySelectorAll("[role='turbo-tooltip']")).forEach((trigger) => {
        trigger.dataset.turboPrefetch = false
        trigger.onclick = (e) => {
          this.verifyCache(e, trigger)
          this.setTooltipPosition(trigger)
          this.last_trigger = trigger
          setTimeout(() => (this.open = true), 100)
        }
      })
    },

    verifyCache(event, trigger) {
      if (this.cached_data[trigger]) {
        event.preventDefault()
        this.last_trigger = undefined
        Turbo.renderStreamMessage(this.cached_data[trigger])
      }
    },

    setTooltipPosition(trigger) {
      const rectangle = trigger.getBoundingClientRect()
      const direction = trigger.dataset.tooltipDirection || "right"
      const opposite_direction = this.oppositeDirection(direction)

      if (["right", "left"].includes(direction)) {
        this.$refs.tooltip.style.setProperty("--tooltip-top-position", `${window.scrollY + rectangle.top}px`)
        this.$refs.tooltip.style.setProperty(
          `--tooltip-${opposite_direction}-position`,
          `${this.calculateXPosition(trigger, rectangle, opposite_direction)}px`,
        )
        this.$refs.tooltip.style.removeProperty("--tooltip-bottom-position")
        this.$refs.tooltip.style.removeProperty(`--tooltip-${direction}-position`)
      }
    },

    boundariesFrom(element, direction) {
      const styles = getComputedStyle(element)
      const capitalized_direction = direction[0].toUpperCase() + direction.slice(1)
      const border_dimension = ["top", "bottom"].includes(direction) ? "Height" : "Width"

      return (
        parseFloat(styles[`margin${capitalized_direction}`]) +
        parseFloat(styles[`padding${capitalized_direction}`]) +
        parseFloat(styles[`border${capitalized_direction}${border_dimension}`])
      )
    },

    calculateXPosition(element, rectangle, direction) {
      if (direction == "left")
        return window.scrollX + rectangle.left + rectangle.width + this.boundariesFrom(element, direction)

      return (
        document.documentElement.scrollWidth - window.scrollX - rectangle.left + this.boundariesFrom(element, direction)
      )
    },

    oppositeDirection(direction) {
      switch (direction) {
        case "left":
          return "right"
        case "right":
          return "left"
        case "top":
          return "bottom"
        default:
          return "top"
      }
    },
  }))
})
