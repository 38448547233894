$(document).on("click", "[data-analytics]", function () {
  if ($(this).data("ga-fired") == null) {
    $(this).data("ga-fired", true)
    ga("send", "event", $(this).data("analytics"), $(this).data("action"), $(this).data("label"), 1)
  }
})

$(document).on("click", "[data-gtm-checkout]", function (e) {
  let paymentMethod = $(this).data("gtmCheckout")

  const listingsData = $(".cart-summary:first .cart-item")
    .map(function () {
      var listing = $(this).data("listing")

      return {
        name: listing.title,
        id: listing.id,
        price: listing.price.dollars,
        brand: listing.artist.full_name,
        quantity: 1,
      }
    })
    .get()

  dataLayer.push({
    event: "checkout",
    ecommerce: {
      checkout: {
        actionField: {
          step: 1,
          option: paymentMethod,
        },
        products: listingsData,
      },
    },
  })
})
