document.addEventListener("alpine:init", () => {
  Alpine.data("read_more_container", () => ({
    init() {
      if (this.contentTruncated()) return
      this.$refs.button.style.display = "none"
    },
    contentTruncated() {
      return this.$refs.container.offsetHeight < this.$refs.container.scrollHeight
    },
    expand() {
      let classes = []
      this.$refs.container.classList.forEach((className) => {
        if (className.includes("line-clamp")) classes.push(className)
      })
      this.$refs.container.classList.remove(...classes)
      this.$refs.button.style.display = "none"
    },
  }))
})
